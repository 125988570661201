export enum PusherState {
  initialized = "initialized",
  connecting = "connecting",
  connected = "connected",
  unavailable = "unavailable",
  failed = "failed",
  disconnected = "disconnected"
}

export enum PusherChannelEvent {
  subscriptionSucceeded = "pusher:subscription_succeeded",
  subscriptionFailed = "pusher:subscription_error",
}