import { Pusher } from "./adapters/Pusher.implementation";
import { EnvironmentHelper } from "./EnvironmentHelper";
import { AuthHelper } from "./AuthHelper";
import { AuthStrategy, getAuthHeaders } from "./AuthHelper/AuthHeaders";
import { PusherChannel } from "./adapters/PusherChannel.implementation";
import { DebugLogger } from "./DebugLogger";

export * from "./PusherHelper/SessionEvent";
export * from "./adapters/PusherChannel.implementation";
export * from "./PusherHelper/types";

const debugLogger = new DebugLogger("📡 Pusher");

const buildConfig = () => {
  const authBackEndURL = EnvironmentHelper.getEnvironmentURL();
  const pusherAuthEndpoint = AuthHelper.getPusherAuthEndpoint();
  return {
    authEndpoint: `${authBackEndURL}${pusherAuthEndpoint}`,
    auth: {
      headers: getAuthHeaders(AuthStrategy.Legacy)
    },
    cluster: "mt1"
  };
};

function formatName(channelName: string): string {
  // Prefix with `presence-` or `private-` to tell Pusher it is a private channel
  return `presence-${channelName}`;
}

class PusherHelper {
  pusher: Pusher | null = null;
  channels: { [Identifier: string]: PusherChannel } = {};

  async init(config = {}): Promise<void> {
    const pusherAppKey = AuthHelper.getPusherAppKey();

    await this.clear();
    this.pusher = new Pusher();
    await this.pusher.init(pusherAppKey, {
      ...buildConfig(),
      ...config
    })
  }

  async clear(): Promise<void> {
    this.channels = {};
    await this.pusher?.disconnect();
    this.pusher = null;
  }

  onConnected(callback: () => any): void {
    this.validatePusher();
    this.pusher!.onConnected(callback);
  }

  onDisconnected(callback: () => any): void {
    this.validatePusher();
    this.pusher!.onDisconnected(callback);
  }

  onError(callback: (error: string) => any): void {
    this.validatePusher();
    this.pusher!.onError(callback);
  }

  async subscribeToChannel(channelName: string): Promise<PusherChannel> {
    const name = formatName(channelName);
    return this.subscribeToChannelByName(name);
  }

  async subscribeToPublicChannel(channelName: string): Promise<PusherChannel> {
    return this.subscribeToChannelByName(channelName);
  }

  private async subscribeToChannelByName(
    channelName: string
  ): Promise<PusherChannel> {
    this.validatePusher();
    let channel = this.channels[channelName];
    if (!channel) {
      channel = new PusherChannel();
      await channel.subscribe(this.pusher!, channelName);
    }
    this.channels[channelName] = channel;
    await channel.init();
    return channel;
  }

  unsubscribeFromChannel(channel: PusherChannel): void {
    this.validatePusher();
    const name = channel.name;
    this.pusher!.unsubscribe(name);
    delete this.channels[name];
  }

  validatePusher() {
    if (!this.pusher) {
      debugLogger.error("Pusher is not initialized.");
      throw Error("Cannot proceed. Call `PusherHelper.init` first.");
    }
  }

  setDebugLog(active: boolean): void {
    this.pusher?.setDebugLog(active, debugLogger);
  }

  unbind(event: string | undefined, callback: Function) {
    this.pusher?.unbind(event, callback);
  }
}

export const pusherHelper = new PusherHelper();
