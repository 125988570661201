export class DebugLogger {
  private tag: string;

  constructor(tag: string = "") {
    this.tag = tag.toUpperCase();
  }

  success(message: string, ...data) {
    this.logMessage(message, `${this.tag}`, "#99cc33", ...data);
  }

  info(message: string, ...data) {
    this.logMessage(message, `${this.tag}`, "#4368af", ...data);
  }

  warning(message: string, ...data: any) {
    this.logMessage(message, `${this.tag}`, "#f39402", ...data);
  }

  error(message: string, ...data: any) {
    this.logMessage(message, `${this.tag}`, "#cc3300", ...data);
  }

  private logMessage(
    message: string,
    tag: string,
    color: string,
    ...data: any
  ) {
    console.log(
      `%c${tag}%c ${message}`,
      `background: ${color}; color: #FFF; padding: 2px 5px; font-weight: bold; border-radius: 5px`,
      "color: default",
      ...data
    );
  }
}
