type VersionedResponse = {
  version: number;
  body: string | null;
};

enum Messages {
  Version = "version",
  Capture = "capture" 
}

function callExtension(
  extensionId: string,
  requiredVersion: number,
  resolve: Function,
  reject: Function,
  message: Messages = Messages.Capture
): void {
  (window as any).chrome.runtime.sendMessage(
    extensionId,
    message,
    (response: VersionedResponse) => {
      if ((window as any).chrome.runtime.lastError) {
        if (
          (window as any).chrome.runtime.lastError.message ===
          "The message port closed before a response was received."
        ) {
          resolve(null); // Timeout
        } else {
          reject(
            new Error(
              `Please install our <a href="https://chrome.google.com/webstore/detail/${extensionId}" target="_blank" rel="noopener noreferrer">Yup Chrome extension</a>.`
            )
          );
        }
      } else if (!response) {
        reject(new Error("Chrome extension is not reachable."));
      } else {
        const { version, body } = response;
        if (!(version && version >= requiredVersion)) {
          reject(
            new Error(
              `Please update our <a href="https://chrome.google.com/webstore/detail/${extensionId}" target="_blank" rel="noopener noreferrer">Yup Chrome extension</a> to the latest version.`
            )
          );
        } else {
          resolve(body);
        }
      }
    }
  );
}

export async function usingChromeExtension(
  extensionId: string,
  requiredVersion: number
) {
  try {
      await new Promise((resolve: Function, reject: Function) =>
          callExtension(extensionId, requiredVersion, resolve, reject, Messages.Version)
      );
      return true;
  } catch (e) {
      return false;
  }
}

export const capture: Function = (
  extensionId: string,
  requiredVersion: number,
  callback?: Function
) => {
  if (callback) {
    return callExtension(
      extensionId,
      requiredVersion,
      (value: string | null) => callback(null, value),
      (error: Error | null) => callback(error, null)
    );
  } else {
    return new Promise((resolve: Function, reject: Function) =>
      callExtension(extensionId, requiredVersion, resolve, reject)
    );
  }
};
