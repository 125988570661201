import { globalObject } from "../adapters/Global";
import { draggable } from "../adapters/draggable";

class EnvironmentBadgeClass {
  domElement: HTMLElement | null = null;
  render(env: string): void {
    if (!globalObject.document) {
      return;
    }

    // Badge is a singleton ui component
    if (!this.domElement) this.domElement = createBadgeElement();

    // Update env display
    const formattedEnv = env.replace("-", " ").toUpperCase();
    this.domElement!.childNodes[1].textContent = `Environment: ${formattedEnv}`;
  }
  clear() {
    this.domElement?.remove();
    this.domElement = null;
  }
}

export default new EnvironmentBadgeClass();

function createBadgeElement(): HTMLElement {
  const badgeElement = createElementWithStyle(badgeStyle);
  globalObject.document.body.appendChild(badgeElement);

  // Decorate with an activity indicator
  badgeElement.appendChild(createElementWithStyle(activityStyle));

  // Add text
  badgeElement.appendChild(createElementWithStyle());
  draggable(badgeElement);

  return badgeElement;
}

function createElementWithStyle(style = {}): HTMLElement {
  const element = globalObject.document.createElement("div");
  Object.assign(element.style, style);
  return element;
}

const badgeStyle = {
  // Box position
  position: "absolute",
  top: "16px",
  left: "16px",
  "z-index": 10000,
  // Box design
  "background-color": "rgba(0, 0, 0, 0.8)",
  "border-radius": "8px",
  // Text font
  "font-family": "Monospace",
  "font-size": "12px",
  color: "white",
  padding: "8px 16px 8px 32px",
};

const colorActive = "#28d26a";
const activityStyle = {
  position: "absolute",
  top: "12px",
  left: "16px",
  width: "8px",
  height: "8px",
  "border-radius": "4px",
  "background-color": colorActive,
};
