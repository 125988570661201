import { LocalStorage, AsyncStorageInterface } from "./adapters/LocalStorage";

const cache = new Map();

class LocalStorageHelperClass {
  storage: AsyncStorageInterface;

  constructor() {
    this.storage = LocalStorage.storage;
  }

  clearCache(): void {
    cache.clear();
  }

  async set(key: string, value: string): Promise<void> {
    cache.set(key, value);
    await this.storage.setItem(key, value);
  }

  async get(key: string): Promise<string | null> {
    if (cache.has(key)) return cache.get(key);
    const value = await this.storage.getItem(key);
    if (value !== null) cache.set(key, value);
    return value;
  }

  async remove(key: string): Promise<void> {
    cache.delete(key);
    await this.storage.removeItem(key);
  }

  setSync(key: string, value: string): void {
    cache.set(key, value);
    this.storage.setItem(key, value);
  }

  getSync(key: string): string | null {
    if (cache.has(key)) return cache.get(key);
    return null;
  }

  removeSync(key: string): void {
    cache.delete(key);
    this.storage.removeItem(key);
  }
}

const LocalStorageHelper = new LocalStorageHelperClass();
export { LocalStorageHelper };
