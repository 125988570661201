const DEFAULT_N_RETRIES = 1;
const DEFAULT_MS_DELAY = 1000;

const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export async function retry(
  funcToRetry: Function,
  max_retries: number = DEFAULT_N_RETRIES,
  max_ms_delay: number = DEFAULT_MS_DELAY
): Promise<any> {
  try {
    const _func = async (retries: number): Promise<any> => {
      try {
        return await funcToRetry();
      } catch (error) {
        if (retries > 0) {
          await sleep(max_ms_delay);
          return _func(--retries);
        } else {
          throw error;
        }
      }
    };
    // Start recursive call
    return _func(max_retries);
  } catch (error) {
    throw error;
  }
}
