import { AuthParams } from "./AuthParams";

export type AuthHeaders = {
  [key: string]: string | number;
};

export enum AuthStrategy {
  Legacy = "Legacy",
  BearerToken = "BearerToken"
}

export function getAuthHeaders(strategy: AuthStrategy): AuthHeaders {
  if (strategy == AuthStrategy.BearerToken) return getAuthHeadersV2();

  return getLegacyAuthHeaders();
}

function getLegacyAuthHeaders(): AuthHeaders {
  const userId = AuthParams.getUserId();
  const accessToken = AuthParams.getAccessToken();

  if (userId && accessToken) {
    return {
      accept: "application/json.v2",
      "X-Has-User-Account": "true",
      "X-User-Known-Key": userId,
      "X-User-Authentication-Token": accessToken
    };
  } else {
    return {};
  }
}

function getAuthHeadersV2(): AuthHeaders {
  const userId = AuthParams.getUserId();
  const accessToken = AuthParams.getAccessToken();

  if (userId && accessToken) {
    return {
      accept: "application/json.v2",
      "X-Yup-Identifier": userId,
      Authorization: `Bearer ${accessToken}`
    };
  } else {
    return {};
  }
}
