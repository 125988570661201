import EnvironmentBadge from "./EnvironmentHelper/EnvironmentBadge";
import { Environment, EnvironmentName } from "./adapters/Environment";
export {
  EnvironmentName,
  StandardEnvironment,
  DeveloperEnvironment,
} from "./adapters/Environment";

class EnvironmentHelperClass {
  env: Environment | null = null;

  async clear(): Promise<void> {
    await this.env?.clear();
    this.env = await this.init();
  }

  async init(envName?: string): Promise<Environment> {
    this.env = new Environment();
    await this.env.init(envName);
    if (this.env.isProduction()) {
      EnvironmentBadge.clear();
    } else {
      EnvironmentBadge.render(this.env.name);
    }
    return this.env;
  }

  get currentEnvironment(): EnvironmentName {
    this.verify();
    return this.env!.name;
  }

  get isProduction() {
    this.verify();
    return this.env!.isProduction();
  }

  getEnvironmentURL(env?: EnvironmentName): string {
    this.verify();
    // production maps to production
    if (this.env!.isProduction()) {
      return "https://www.yup.com";
    }

    // development maps to localhost
    if (this.env!.isDevelopment()) {
      return "http://localhost:3000";
    }

    // standard non-production environments map to yupinternal.com
    if (this.env!.isStandardEnvironment()) {
      return `https://${this.env!.name}.yupinternal.com`;
    }

    // developer environments map to developer's ngrok
    if (this.env!.isDeveloperEnvironment()) {
      return `https://${this.env!.name}.ngrok.io`;
    }

    // random environments are redirected to local for security
    return "http://localhost:3000";
  }

  private verify(): void {
    if (!this.env)
      throw new Error(
        "Environment not initialized. Call `await EnvironmentHelper.init()` first."
      );
  }
}

export const EnvironmentHelper = new EnvironmentHelperClass();
