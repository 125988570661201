import {
  LocalStorageInterface,
  AsyncStorageInterface,
} from "./LocalStorage.interface";

export class LocalStorageImplementation implements LocalStorageInterface {
  static get storage(): AsyncStorageInterface {
    return {
      getItem: async (key: string): Promise<string | null> =>
        window.localStorage.getItem(key),
      setItem: async (key: string, value: string): Promise<void> =>
        window.localStorage.setItem(key, value),
      removeItem: async (key: string): Promise<void> =>
        window.localStorage.removeItem(key),
    };
  }
}
