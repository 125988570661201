import { AuthParams } from "./AuthHelper/AuthParams";
import { NetworkHelper } from "./NetworkHelper";
import { Timers } from "./Timers";

const AUTH_CACHE_LIFETIME = 200;

const IS_AUTH_TIMERS_LABEL = "AuthHelper.authorizeWithServer";

class AuthHelperClass {
  serverAuthorizedAt: null | Date = null;
  isServerAuthorized: boolean = false;

  clearAuthParams() {
    AuthParams.clearAuthParams();
    Timers.clear(IS_AUTH_TIMERS_LABEL);
  }

  getUserId() {
    return AuthParams.getUserId();
  }

  getAccessToken() {
    return AuthParams.getAccessToken();
  }

  getPusherAuthEndpoint(): string {
    return AuthParams.getPusherAuthEndpoint();
  }

  getPusherAppKey(): string {
    return AuthParams.getPusherAppKey();
  }

  getExpiresAt(): string {
    return AuthParams.getExpiresAt();
  }

  async isAuthenticated(): Promise<boolean> {
    const initialized = await AuthParams.authParamsInitialized();
    const expiresAt = new Date(this.getExpiresAt() || 0);

    if (!(initialized && new Date() < expiresAt)) {
      // we locally know you aren't authenticated
      return false;
    }

    return await Timers.setMemo<boolean>({
      label: IS_AUTH_TIMERS_LABEL,
      callback: this.authorizeWithServer,
      delay: AUTH_CACHE_LIFETIME,
    });
  }

  setAuthParams(response: {
    identifier: string;
    access_token: string;
    settings?: any;
  }): void {
    AuthParams.setAuthParams(response);
  }

  async authorizeWithServer(): Promise<boolean> {
    const response = await NetworkHelper.fetchRawResponse({
      endPoint: "/api/authenticate_user",
      method: "PUT",
    });

    if (response.status === 200) {
      // looking good, check body
      return (await response.json()).success;
    } else if (response.status === 401) {
      // server says we weren't authenticated
      return false;
    } else {
      // server isn't available
      console.log(response.status, response);
      throw new Error("Authentication server unavailable");
    }
  }
}

export const AuthHelper = new AuthHelperClass();
