export enum SessionEvent {
  tutorFound = "client-tutor-found",
  tutorReady = "client-tutor-ready",
  messageReceived = "client-message-sent",
  sessionEnded = "client-session-ended",
  startedTyping = "client-is-typing",
  startedDrawing = "client-started-drawing",
  minutesUpdate = "client-minutes-update",
  pausedSession = "client-paused-session",
  startedLiveDrawing = "client-started-live-drawing",
  endedLiveDrawing = "client-ended-live-drawing",
  receivedHandshake = "client-received-handshake",
}
