import {
  EnvironmentName,
  StandardEnvironment,
  DeveloperEnvironment
} from "./Environment.interface";
export {
  EnvironmentName,
  StandardEnvironment,
  DeveloperEnvironment
} from "./Environment.interface";

import { EnvironmentImplementation } from "./Environment.implementation";
const environmentInstance = new EnvironmentImplementation();

import { LocalStorageHelper } from "../LocalStorageHelper";

const STORAGE_KEY = "yup-env";

function isEnvironment(env: string): env is EnvironmentName {
  return env in StandardEnvironment || env in DeveloperEnvironment;
}

function sanitizeEnv(
  env: string,
  defaultEnv: EnvironmentName
): EnvironmentName {
  let sanitizedEnv = env.toLowerCase().replace("-", "");
  if (sanitizedEnv.startsWith("prod")) {
    sanitizedEnv = StandardEnvironment.production;
  }
  return isEnvironment(sanitizedEnv) ? sanitizedEnv : defaultEnv;
}

export class Environment {
  async init(name?: string): Promise<void> {
    const contextEnv = this.contextEnvironment();
    const currentEnv = await LocalStorageHelper.get(STORAGE_KEY);
    const defaultEnv = this.defaultEnvironment();
    const sanitizedName = sanitizeEnv(
      name ?? contextEnv ?? currentEnv ?? "",
      defaultEnv
    );
    await LocalStorageHelper.set(STORAGE_KEY, sanitizedName);
  }

  async clear(): Promise<void> {
    await LocalStorageHelper.remove(STORAGE_KEY);
  }

  get name(): EnvironmentName {
    return sanitizeEnv(
      LocalStorageHelper.getSync(STORAGE_KEY) ?? "",
      this.defaultEnvironment()
    );
  }

  isProduction(): boolean {
    return this.name === StandardEnvironment.production;
  }

  isDevelopment(): boolean {
    return this.name === StandardEnvironment.development;
  }

  isStandardEnvironment(): boolean {
    return this.name in StandardEnvironment;
  }

  isDeveloperEnvironment(): boolean {
    const name = this.name.split(/\d+/)[0];
    return name in DeveloperEnvironment;
  }

  defaultEnvironment(): StandardEnvironment {
    return environmentInstance.defaultEnvironment();
  }
  contextEnvironment(): string | null {
    return environmentInstance.contextEnvironment();
  }
}
