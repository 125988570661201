import { globalObject } from "./adapters/Global";
import {DebugLogger} from "./DebugLogger";

interface Timer {
    id: number;
    clear: Function;
}

class TimeoutTimer implements Timer {
  id: number;
  constructor(callback: () => any, timeoutMilliseconds: number) {
    this.id = globalObject.setTimeout(callback, timeoutMilliseconds);
  }
  clear(): void {
    globalObject.clearTimeout(this.id);
  }
}

class IntervalTimer implements Timer {
  id: number;
  constructor(callback: () => any, intervalMilliseconds: number) {
    this.id = globalObject.setInterval(callback, intervalMilliseconds);
  }
  clear(): void {
    globalObject.clearInterval(this.id);
  }
}

class TimersClass {
    private debug = false;
    private debugLogger: DebugLogger;
    private timers: { [Identifier: string]: Timer } = {};
    private memos: { [Identifier: string]: any } = {};

    constructor(tag: string = "") {
        this.debugLogger = new DebugLogger(tag ? `⌛ ${tag} Timer` : "⌛ Timer");
    }

    clear(label: string): void {
        this.timers[label]?.clear();
        delete this.timers[label];
    }

    hasTimer(label: string): boolean {
        return !!this.timers[label];
    }

    /**
     * This timer type behaves like a standard interval for
     * asynchronous requests. Instead of dispatching the callback
     * every n seconds, it waits for the current asynchronous
     * invocation to complete before scheduling the next one.
     */
    setRecursiveTimeout({ label, callback, delay }: TimerInfo): void {
        const recursiveCallback = async () => {
            try {
                await callback();
            } catch (error) {
                console.error(error.message);
            }
            if (this.hasTimer(label)) {
                this.timers[label] = new TimeoutTimer(recursiveCallback, delay);
            }
        };
        this.clear(label);
        this.timers[label] = new TimeoutTimer(recursiveCallback, delay);
        this.debugPrint(`Activated a new recursive timeout [${label}].`);
    }

    setTimeout({ label, callback, delay }: TimerInfo): void {
        this.clear(label);
        this.timers[label] = new TimeoutTimer(callback, delay);
        this.debugPrint(`Activated a new timeout [${label}].`);
    }

    setInterval({ label, callback, delay }: TimerInfo): void {
        this.clear(label);
        this.timers[label] = new IntervalTimer(callback, delay);
        this.debugPrint(`Activated a new interval timeout [${label}].`);
    }

    private setTimer(label: string, delay: number): void {
        this.setTimeout({
            label,
            callback: () => this.clear(label),
            delay,
        });
    }

    setThrottle({ label, callback, delay }: TimerInfo): boolean {
        if (this.hasTimer(label)) return false;
        this.setTimer(label, delay);
        callback();
        return true;
    }

    async setMemo<T>({ label, callback, delay }: TimerInfo): Promise<T> {
        const hasExpired = delay > 0 && !this.hasTimer(label);
        if (hasExpired || this.memos[label] === undefined) {
            this.setTimer(label, delay);
            this.memos[label] = await callback();
        }
        return this.memos[label];
    }

    debugMode(): void {
        this.debug = true;
    }

    private debugPrint(message: string): void {
        if (this.debug) this.debugLogger.info(message);
    }
}

export type TimerInfo = {
    label: string;
    callback: () => any;
    delay: number;
};

const Timers = new TimersClass();
export { Timers };
