/* Makes an HTML element draggable by its handle */
export const draggable = function (el: HTMLElement) {
  const pos = getPosition();
  setPosition(el, pos.x, pos.y);

  let startX: number = pos.x,
    startY: number = pos.y,
    x: number = el.getBoundingClientRect().left + window.scrollX,
    y: number = el.getBoundingClientRect().top + window.scrollY;

  el.style.cursor = "move";

  el.addEventListener("mousedown", mousedown);

  function mousedown(event: MouseEvent) {
    // Prevent default dragging of selected content
    event.preventDefault();
    startX = event.screenX - x;
    startY = event.screenY - y;
    window.document.addEventListener("mousemove", mousemove, true);
    window.document.addEventListener("mouseup", mouseup, false);
  }

  function mousemove(event: MouseEvent) {
    x = Math.max(0, event.screenX - startX);
    y = Math.max(0, event.screenY - startY);
    setPosition(el, x, y);
  }

  function mouseup() {
    window.document.removeEventListener("mousemove", mousemove, true);
    window.document.removeEventListener("mouseup", mouseup, true);
  }
};

function getPosition(): { x: number; y: number } {
  return { x: 0, y: 0 };
}

function setPosition(el: HTMLElement, x: number, y: number) {
  el.style.left = x + "px";
  el.style.top = y + "px";
}
