interface QueueOptions {
  maxQueueSize?: number;
  maxElementSize?: number;
}

interface QueueElement<Type> {
  value: Type;
  previous: QueueElement<Type> | null;
  next: QueueElement<Type> | null;
}

class Queue<Type> {
  name: string;
  maxQueueSize: number;
  maxElementSize: number;

  first: QueueElement<Type> | null = null;
  last: QueueElement<Type> | null = null;
  length: number = 0;

  constructor(name: string, options?: QueueOptions) {
    this.name = name;
    this.maxQueueSize = options?.maxQueueSize ?? 0;
    this.maxElementSize = options?.maxElementSize ?? 0;
  }

  clear(): void {
    this.first = this.last = null;
    this.length = 0;
  }

  enqueue(data: Type): void {
    this.assertEnqueue(data);
    const element: QueueElement<Type> = {
      value: data,
      previous: null,
      next: null
    };
    if (!this.first) {
      this.first = this.last = element;
    } else {
      // Add at the beginning
      element.next = this.first;
      element.next.previous = element;
      this.first = element;
    }
    this.length++;
  }

  dequeue(): Type | null {
    if (!this.last) {
      return null;
    }
    // Remove from the end
    const element = this.last;
    if (!element.previous) {
      this.first = this.last = null;
    } else {
      element.previous.next = null;
      this.last = element.previous;
    }
    this.length--;
    return element.value;
  }

  dequeueBatch(maxBatchSize = 1): Type[] {
    const batchElements: Type[] = [];
    while (maxBatchSize > 0 && this.length > 0) {
      batchElements.push(this.dequeue()!);
      maxBatchSize--;
    }
    return batchElements;
  }

  restoreBatch(dataArray: Type[]): void {
    if (!dataArray.length) return;

    const { name, maxQueueSize, maxElementSize } = this;
    const tmpQueue = new Queue<Type>(name, { maxQueueSize, maxElementSize });
    for (const data of dataArray) tmpQueue.enqueue(data);
    // Concatenate the array to the end of this queue
    if (this.last) {
      this.last.next = tmpQueue.first!;
      this.last.next.previous = this.last;
    } else {
      this.first = tmpQueue.first!;
    }
    this.last = tmpQueue.last;
    this.length += tmpQueue.length;
  }

  peek(): Type | null {
    return this.first?.value ?? null;
  }

  toArray(): Type[] {
    const elements: Type[] = [];
    let currentElement = this.last;
    while (currentElement) {
      elements.push(currentElement.value);
      currentElement = currentElement.previous;
    }
    return elements;
  }

  private assertEnqueue(data: Type): void {
    const dataSize = JSON.stringify(data).length;
    if (this.maxQueueSize > 0 && this.length >= this.maxQueueSize) {
      throw new Error(
        `Cannot enqueue in ${this.name}. Queue has size: ${this.length} >= ${this.maxQueueSize}`
      );
    }
    if (this.maxElementSize > 0 && dataSize > this.maxElementSize) {
      throw new Error(
        `Cannot enqueue in ${this.name}. Element has size ${dataSize} > ${this.maxElementSize}`
      );
    }
  }
}

export { Queue };
