import {
  EnvironmentInterface,
  StandardEnvironment,
} from "./Environment.interface";

function isProductionHostname(): boolean {
  return window.location.hostname.endsWith("yup.com");
}
function envFromUrl(): string | null {
  return new URLSearchParams(window.location.search).get("env");
}

export class EnvironmentImplementation implements EnvironmentInterface {
  defaultEnvironment(): StandardEnvironment {
    return isProductionHostname()
      ? StandardEnvironment.production
      : StandardEnvironment.development;
  }
  contextEnvironment(): string | null {
    return envFromUrl();
  }
}
