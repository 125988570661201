// List common environments and allow fuzzy strings for ngrok
// keys have to be lowercase to match
export enum StandardEnvironment {
  development = "development",
  production = "production",
  staging1 = "staging1",
  staging2 = "staging2",
  giddy = "giddy",
  qa1 = "qa1",
  qa2 = "qa2",
  qa3 = "qa3"
}

export enum DeveloperEnvironment {
  rapha = "rapha",
  jordan = "jordan",
  ruturaj = "ruturaj"
}

export type EnvironmentName = StandardEnvironment | DeveloperEnvironment;

export interface EnvironmentInterface {
  defaultEnvironment(): StandardEnvironment;
  contextEnvironment(): string | null;
}
