import PusherClass, { Channel } from "pusher-js";
import { PusherState } from "../PusherHelper/types";
import { PusherInterface } from "./Pusher.interface";
import { DebugLogger } from "../DebugLogger";

enum PusherStateEvent {
  error = "error",
  connected = "connected",
  disconnected = "disconnected",
  stateChange = "state_change"
}

type PusherStateChange = {
  previous: PusherState;
  current: PusherState;
}

class Pusher implements PusherInterface {
  pusher: PusherClass | null = null;

  async init(apiKey: string, config: any) {
    this.pusher = new PusherClass(apiKey, config);
  }

  async connect() {
    this.pusher?.connect();
  }

  async disconnect() {
    this.pusher?.disconnect();
  }

  async subscribe(options: any = {}) {
    return this.pusher!.subscribe(options.channelName)
  }

  async unsubscribe(channelName: string) {
    this.pusher?.unsubscribe(channelName)
  }

  unbind(event: string | undefined, callback: Function) {
    this.pusher?.connection.unbind(event, callback);
  }

  setDebugLog(active: boolean, logger: DebugLogger) {
    PusherClass.logToConsole = active;
    PusherClass.log = (msg) => PusherClass.logToConsole && logger.info(msg);
  }

  onConnected(callback: () => any): void {
    this.pusher!.connection.bind(
      PusherStateEvent.stateChange,
      (state: PusherStateChange) => this.hasConnected(state) && callback()
    );
  }

  onDisconnected(callback: () => any): void {
    this.pusher!.connection.bind(
      PusherStateEvent.stateChange,
      (state: PusherStateChange) => this.hasDisconnected(state) && callback()
    );
  }

  onError(callback: (error: string) => any): void {
    this.pusher!.connection.bind(PusherStateEvent.error, callback);
  }

  private hasDisconnected(stateChange: PusherStateChange): boolean {
    return stateChange.previous === PusherState.connected;
  }

  private hasConnected(stateChange: PusherStateChange): boolean {
    return stateChange.current === PusherState.connected;
  }
}

export { Pusher, Channel };
