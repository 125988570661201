import { DebugLogger } from "./DebugLogger";

/*
 * Types.
 * ----------------------------
 */

export interface ObservableInterface {
    debugLogger: DebugLogger;
    subscribe: (context: any, event: string, callback: Function) => void;
    publish: (event: string, data: object) => void;
    unsubscribe: (context: any, event: string) => void;
    debugMode: () => void;
}

const observers: { [key: string]: { [context: string]: Function } } = {};

let debugMode = false;

/**
 * Construct an Observable object
 * @constructor
 */
const Observable = (function(tag: string = "") {
    // @ts-ignore
    this.debugLogger = new DebugLogger(tag ? `📣 ${tag} Observable` : "📣 Observable");
} as any) as { new (tag: string): ObservableInterface };

Observable.prototype.debugLogger = null;

/**
 * Subscribes to an event identified by the current context.
 * @param {any} context - The observer object.
 * @param {string} event - The name of the event.
 * @param {Function} callback - Function object.
 */
Observable.prototype.subscribe = function(context: any, event: string, callback: Function) {
    context = context.name;
    if (observers[event] && !!observers[event][context]) return;

    if (debugMode) {
        this.debugLogger.info(`Observing ${event} on ${context}.`);
    }
    if (!observers[event]) observers[event] = {};
    observers[event][context] = callback;
};

/**
 * Publish an event to all active observables.
 * @param {string} event - The name of the event.
 * @param {any} data - The data that will be passed to observers.
 */
Observable.prototype.publish = function(event: string, data: object) {
    if (!observers[event]) {
        if (debugMode) {
            this.debugLogger.info(`There are no observers for ${event}.`);
        }
        return;
    }
    Object.keys(observers[event]).forEach((context) => observers[event][context](data));
};

/**
 * Unsuscribe from an event identified by the current context.
 * @param {any} context - The observer object.
 * @param {string} event - The name of the event.
 */
Observable.prototype.unsubscribe = function(context: any, event: string) {
    context = context.name;
    if (debugMode) {
        this.debugLogger.info(`Unsubscribing from ${event} on ${context}.`);
    }
    if (observers[event] && observers[event][context]) {
        delete observers[event][context];
    }
};

/**
 * Activates debug log messages.
 */
Observable.prototype.debugMode = (): void => {
    debugMode = true;
};

export { Observable };
