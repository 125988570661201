import { Navigator, YupNetworkInfo } from "../types";

// this is a workaround for TypeScript not having correct definitions for window.navigator
function getConnection() {
  // @ts-ignore
  const navigator = window.navigator as Navigator;
  const connection =
    navigator.connection ||
    navigator.mozConnection ||
    navigator.webkitConnection;
  if (!connection)
    return {
      status: "unavailable",
    };
  return {
    status: "available",
    bandwidth: connection.downlink,
    effectiveType: connection.effectiveType,
    rtt: connection.rtt,
  };
}

export function getYupNetworkInfo(): YupNetworkInfo {
  return {
    location: window.location.href,
    referrer_domain: window.document.referrer,
    connection: getConnection(),
  };
}
