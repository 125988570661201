import { globalObject } from "./adapters/Global";
import { LocalStorageHelper } from "./LocalStorageHelper";

declare global {
  interface Window {
    onUsersnapCXLoad: Function;
    LogEventQueue: any;
  }
}

type Callback = {
  onOpen?: () => void;
  onSubmit?: () => void;
  onError?: () => void;
  onClose?: () => void;
  customData?: () => { [key: string]: any };
};

type Parameter = {
  projectApiKey: string;
  callbacks?: Callback;
  globalApiKey?: string;
};

const errorLogsKey = "usersnap-error-logs";
const errorLogsMax = 5;

class UsersnapHelper {
  private projectApiKey: string = "";
  private globalApiKey: string = "";
  private callbacks?: Callback = {};
  private usersnap: any = null;
  private errorLogs: Array<string> = [];

  public constructor(param: Parameter) {
    this.projectApiKey = param.projectApiKey;
    this.globalApiKey = param.globalApiKey ?? "";
    this.callbacks = param.callbacks;
  }

  public async initialize() {
    await this.loadScript();

    try {
      const serializedLogs = LocalStorageHelper.getSync(errorLogsKey);
      this.errorLogs = JSON.parse(serializedLogs ?? "[]");
    } catch (err) {
      console.error(err);
    }
  }

  public open() {
    this.usersnap?.open();
  }

  public hideButton() {
    this.usersnap?.hideButton();
  }

  public showButton() {
    this.usersnap?.showButton();
  }

  public triggerEvent(eventName: string) {
    this.usersnap?.logEvent?.(eventName);
  }

  public addErrorLog(log: string): void {
    while (this.errorLogs.length >= errorLogsMax) {
      this.errorLogs.shift(); // Remove oldest log
    }
    this.errorLogs.push(log);
    try {
      const serializedLogs = JSON.stringify(this.errorLogs);
      LocalStorageHelper.setSync(errorLogsKey, serializedLogs);
    } catch (err) {
      console.error(err);
    }
  }

  private async loadScript(): Promise<void> {
    const url = this.globalApiKey?
      `https://widget.usersnap.com/global/load/${this.globalApiKey}?onload=onUsersnapCXLoad` :
      `https://widget.usersnap.com/load/${this.projectApiKey}?onload=onUsersnapCXLoad`;
    return new Promise((resolve, reject) => {
      try {
        globalObject.onUsersnapCXLoad = (api: any) => {
          this.usersnap = api;
          this.usersnap.init();
          this.bind();
          resolve();
        };
        const script: any = globalObject.document?.createElement("script");
        script.async = 1;
        script.src = url;
        globalObject.document?.getElementsByTagName("head")[0].appendChild(script);
      } catch(error) {
        reject(error)
      }
    });
  }

  private bind(): void {
    if (!this.usersnap) {
      console.error("Usersnap API is undefined");
      return;
    }
    this.usersnap.on("open", this.onOpen.bind(this));
    this.usersnap.on("error", this.onError.bind(this));
    this.usersnap.on("submit", this.onSubmit.bind(this));
    this.usersnap.on("close", this.onClose.bind(this));
  }

  private onOpen(event: any): void {
    const customData = this.callbacks?.customData?.() ?? {};
    event.api.setValue("custom", {
      ...customData,
      errorLogs: this.errorLogs,
    });
    this.callbacks?.onOpen?.();
  }

  private onClose(): void {
    this.callbacks?.onClose?.();
  }

  private onSubmit(): void {
    LocalStorageHelper.removeSync(errorLogsKey);
    this.errorLogs = [];
    this.callbacks?.onSubmit?.();
  }

  private onError(): void {
    this.callbacks?.onError?.();
  }
}

export { UsersnapHelper };
