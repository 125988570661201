import { LocalStorageHelper } from "../LocalStorageHelper";
import addDays from "date-fns/addDays";

const STORAGE_PREFIX = "yup-user";
const AUTH_PARAMS = [
  `${STORAGE_PREFIX}-id`,
  `${STORAGE_PREFIX}-access-token`,
  `${STORAGE_PREFIX}-expires-at`,
  `${STORAGE_PREFIX}-pusher-auth-endpoint`,
  `${STORAGE_PREFIX}-pusher-app-key`,
];

const IS_AUTH_TIMERS_LABEL = "AuthHelper.authorizeWithServer";

class AuthParamsClass {
  serverAuthorizedAt: null | Date = null;
  isServerAuthorized: boolean = false;

  async reloadAuthParams(): Promise<void> {
    await Promise.all(
      AUTH_PARAMS.map((param) => LocalStorageHelper.get(param))
    );
  }

  async authParamsInitialized(): Promise<boolean> {
    await this.reloadAuthParams();
    return this.getUserId() !== null && this.getAccessToken() !== null;
  }

  clearAuthParams() {
    AUTH_PARAMS.forEach((param) => LocalStorageHelper.removeSync(param));
  }

  getUserId() {
    const userId = LocalStorageHelper.getSync(`${STORAGE_PREFIX}-id`) ?? "";
    return userId !== "" ? userId : null;
  }

  getAccessToken() {
    const token =
      LocalStorageHelper.getSync(`${STORAGE_PREFIX}-access-token`) ?? "";
    return token !== "" ? token : null;
  }

  getPusherAuthEndpoint(): string {
    return (
      LocalStorageHelper.getSync(`${STORAGE_PREFIX}-pusher-auth-endpoint`) ?? ""
    );
  }

  getPusherAppKey(): string {
    return LocalStorageHelper.getSync(`${STORAGE_PREFIX}-pusher-app-key`) ?? "";
  }

  getExpiresAt(): string {
    return LocalStorageHelper.getSync(`${STORAGE_PREFIX}-expires-at`) ?? "";
  }

  setAuthParams(response: {
    identifier: string;
    access_token: string;
    settings?: any;
  }): void {
    LocalStorageHelper.setSync(`${STORAGE_PREFIX}-id`, response.identifier);
    LocalStorageHelper.setSync(
      `${STORAGE_PREFIX}-access-token`,
      response.access_token
    );
    const expiresAt: Date = addDays(new Date(), 30);
    LocalStorageHelper.setSync(
      `${STORAGE_PREFIX}-expires-at`,
      expiresAt.toDateString()
    );
    const pusherCredentials = response.settings?.credentials?.pusher;
    if (!pusherCredentials) return;
    LocalStorageHelper.setSync(
      `${STORAGE_PREFIX}-pusher-auth-endpoint`,
      pusherCredentials?.auth_endpoint ?? ""
    );
    LocalStorageHelper.setSync(
      `${STORAGE_PREFIX}-pusher-app-key`,
      pusherCredentials?.key ?? ""
    );
  }
}

export const AuthParams = new AuthParamsClass();
