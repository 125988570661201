import { Channel, Pusher } from "../adapters/Pusher.implementation";
import { Timers } from "../Timers";
import { PusherChannelInterface } from "./PusherChannel.interface";
import { PusherChannelEvent } from "../PusherHelper";

export class PusherChannel implements PusherChannelInterface {
  channel: Channel | null = null;

  get name() {
    return this.channel?.name ?? "";
  }

  init(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.channel?.bind(PusherChannelEvent.subscriptionSucceeded, resolve)
        .bind(PusherChannelEvent.subscriptionFailed, reject);
      if (this.channel?.subscribed) resolve();
    });
  }

  async subscribe(pusher: Pusher, channelName: string) {
    this.unbind_all();
    this.channel = await pusher.subscribe({channelName})
  }

  bind(eventName: string, callback: Function) {
    this.channel?.bind(eventName, callback);
  }

  unbind(eventName: string, callback: Function) {
    this.channel?.unbind(eventName, callback);
  }

  unbind_all() {
    this.channel?.unbind_all();
  }

  async trigger(eventName: string, data: any = {}): Promise<boolean> {
    return this.channel?.trigger(eventName, data) ?? false;
  }

  triggerThrottled(eventName: string, delay: number, data?: any): boolean {
    const label = `${this.name}#${eventName}`;
    return Timers.setThrottle({
      label,
      callback: () => this.trigger(eventName, data),
      delay,
    });
  }
}
