import { Queue } from "./Queue";
import { Timers } from "./Timers";
import { NetworkHelper } from "./NetworkHelper";
import { EnvironmentHelper } from "./EnvironmentHelper";
import { getYupNetworkInfo } from "./adapters/YupNetworkInfo";
import { LocalStorageHelper } from "./LocalStorageHelper";
import { v4 as uuidv4 } from "uuid";

const queue = new Queue("logging_queue", {
  maxQueueSize: 200,
  maxElementSize: 4000
});

async function saveQueueToStorage(): Promise<void> {
  await LocalStorageHelper.set(queue.name, JSON.stringify(queue.toArray()));
}

async function loadQueueFromStorage(): Promise<void> {
  queue.clear();
  const elements = (await LocalStorageHelper.get(queue.name)) ?? "[]";
  queue.restoreBatch(JSON.parse(elements));
}

const BATCH_TIMER_LABEL = "logger_batch";
const BACKUP_TIMER_LABEL = "logger_backup";
export function LogStart(
  batchTime: number = 500,
  backupTime: number = 5000
): void {
  Timers.setRecursiveTimeout({
    label: BATCH_TIMER_LABEL,
    callback: postBatchEvents,
    delay: batchTime
  });
  Timers.setRecursiveTimeout({
    label: BACKUP_TIMER_LABEL,
    callback: saveQueueToStorage,
    delay: backupTime
  });
}
export async function LogBackUp(): Promise<void> {
  await saveQueueToStorage();
}
export async function LogRestore(): Promise<void> {
  await loadQueueFromStorage();
}

export function LogClear(): void {
  queue.clear();
  Timers.clear(BATCH_TIMER_LABEL);
  Timers.clear(BACKUP_TIMER_LABEL);
}

const MAX_BATCH_SIZE = 100;
async function postBatchEvents(): Promise<void> {
  const events = queue.dequeueBatch(MAX_BATCH_SIZE);
  if (!events.length) return;
  try {
    await NetworkHelper.post({
      endPoint: getLogServerEndPoint(),
      params: { events }
    });
  } catch (err) {
    queue.restoreBatch(events);
  }
}

const getLogServerEndPoint = (): string =>
  EnvironmentHelper.isProduction
    ? "https://logs.yup.com/api/log_entries"
    : "/api/log_entries";

export function LogEvent(name: string, payload = {}): void {
  const data = {
    ...payload,
    name,
    time_zone: "N/A", // TODO: use a compatible lib like expo-localization
    uuid: uuidv4(),
    ...getYupNetworkInfo(),
    event_user_timestamp: new Date()
  };

  try {
    queue.enqueue(data);
  } catch (e) {
    throw new Error(`Error enqueueing ${name}: ${e.message}`);
  }
}
